export enum LocationType {
  PRIMARY = 'primary',
  VIEW_ONLY = 'viewOnly',
  BUY_DIRECT = 'buyDirect',
  VIRTUAL = 'virtual',
  ALTERNATE = 'alternate',
  VIRTUAL_BUY_DIRECT = 'virtual/buyDirect',
  VIRTUAL_INQ_ONLY = 'virtual/INQonly',
  ORDER_FROM_PRIMARY = 'orderFromPrimary',
}
