import { ILanguageKeys } from './interface'

export const frCa: Partial<ILanguageKeys> = {
  threeMonths: '3 Mois',
  sixMonths: '6 Mois',
  tenMonths: '10 Mois',
  oneYear: '1 an',
  accessoriesSuppliesAndTools: 'Accessoires, fournitures et outils',
  account: 'Compte',
  add: 'Ajouter',
  ADD: 'Ajouter',
  addA: 'Add a', // TO DO
  addACustomName: 'Ajouter un nom personnalisé',
  addAllToCart: 'Tout ajouter au panier',
  addItems: 'Ajouter articles',
  addItemsToGroup: 'Ajouter des éléments au groupe',
  additionalDetails: 'Détails supplémentaires',
  additionalOptions: 'Additional Options', //TO DO
  addJust: 'Ajouter uniquement',
  addManually: 'AJOUTER MANUELLEMENT',
  addNewList: 'Ajouter une nouvelle liste',
  addParts: 'Ajouter pièces',
  addPartToList: 'Ajouter pièce à la liste',
  addQuoteToCart: 'Ajouter devis au chariot',
  updateAndAddToCart: 'Update And Add To Cart', //TO DO
  addToCart: 'Ajouter au chariot',
  addToList: 'Ajouter à la liste',
  addVehicle: 'Ajouter un véhicule',
  adhesives: 'Adhésifs',
  advancedSearch: 'Recherche Avancée',
  aftermarketPartAvailable: 'Pièce du marché secondaire disponible',
  all: 'Tout',
  actions: 'Actions', //TODO
  all31Days: 'Tout (31 jours)',
  allCarts: 'tous les chariots',
  allItemsFromEachCartWillBeRemovedAndCannotBeUndone:
    'Tous les articles de chaque chariot seront supprimés, sans pouvoir revenir.',
  allItemsFromThisOrderHaveBeenReturned: `Tous les articles de cette commande ont été retournés`,
  allianceConnect: 'Alliance Connect',
  allLines: 'Toutes les lignes',
  allOrders: 'Toutes les commandes',
  allParts: 'Toutes les pièces',
  allProducts: 'Tous les produits',
  allRightsReserved: 'Tous les droits sont réservés',
  allVehicles: 'Tous les véhicules',
  allVehiclesWereSavedAsQuotes:
    'Tous les véhicules ont été sauvegardés comme devis',
  alternateFor: 'Alternative à',
  amount: 'quantité',
  available: 'Disponible',
  amountRequestedCanBeOrderedButWillTakeLongerToBeDelivered:
    'La quantité demandée peut être commandée, mais avec une durée de livraison supérieure',
  amountRequestedUnavailableItCanBeOrderedFromOtherLocation:
    'amount requested unavailable, It can be ordered from other location',
  anAdminWillBeReachingOutToYou:
    'Un administrateur vous contactera. Si vous ne recevez aucun message, veuillez contacter le support à la clientèle.',
  apply: 'Appliquer',
  applyChanges: 'Appliquer les modifications',
  applySort: 'Apply sort', // TODO
  areNotAvailableInOurDatabase:
    'ne sont pas disponibles dans notre base de données.',
  areYouSureYouWantTo: 'Êtes-vous sûr de vouloir',
  areYouSureYouWantToCancelReturnRquest:
    'Êtes-vous sûr de vouloir annuler la demande de retour ?',
  areYouSureYouWantToDelete: 'Êtes-vous sûr de vouloir supprimer',
  areYouSureYouWantToEmptyYour: 'Êtes-vous sûr de vouloir vider le chariot',
  areYouSureYouWantToEmptyYourCart:
    'Êtes-vous sûr de vouloir vider le chariot ?',
  areYouSureYouWantToRemove: 'Êtes-vous sûr de vouloir enlever',
  areYouSureYouWantToRemoveTheReturnRequest: `areYouSureYouWantToRemoveTheReturnRequest :
    « Êtes-vous sûr de vouloir supprimer le % de cette demande de retour ?`,
  areYouSureYouWantToRemoveYour: 'Êtes-vous sûr de vouloir enlever votre',
  areYouSureYouWantToUpdate: 'Êtes-vous sûr de vouloir mettre à jour',
  arrivedLate: 'Arrivé en retard',
  arrivedOn: 'arrivé le %',
  at: 'à',
  automaticEntry: 'entrée automatique.',
  automaticEntryYouHave: 'entrée automatique. Vous avez ',
  availability: 'Disponibilité',
  aVehicleWasNotFound:
    'Un véhicule n’a pas été trouvé. Veuillez sélectionner le véhicule manuellement.',
  awaitingShipment: `En attente d'expédition`,
  back: 'Revenir',
  backToAllDiagrams: 'Revenir à toutes les diagrammes',
  backToTop: 'Retour en haut',
  belowIsAListOfAllOE:
    'Below is a list of all OE and aftermarket parts, including items not sold in our warehouses. This tool is for reference only.', //TODO
  backToCart: 'Revenir au chariot',
  backToLogin: 'Revenir à la connexion',
  backToOrders: 'Revenir à l’historique de commande',
  backToOrderDetails: 'Retour aux détails de la commande',
  backToPartType: 'Retour au type de pièce',
  backToInvoice: 'Back to Invoice Details', //TO DO
  backToPreviousPage: 'Back to Previous Page', //TODO
  backToReturnSelection: 'Retour à la sélection de retour',
  backToReviewItems: 'Revenir à la révision des articles',
  backToSavedQuotes: 'Revenir aux devis sauvegardés',
  bestEmailToReachYouAt: 'Votre adresse de courriel préférée ',
  brand: 'Marque',
  brandAZ: 'Marque (A-Z)',
  brandZA: 'Marque (Z-A)',
  Brands: 'Marques',
  browseFile: 'Parcourir les fichiers',
  buyAgain: 'Acheter à nouveau',
  callForAvailability: 'Appeler pour savoir la disponibilité',
  callForPrice: 'Appeler pour savoir le prix',
  cancel: 'Annuler',
  cancelReturnRequest: 'Annuler la demande de retour',
  cannotDeleteCategory: 'La catégorie ne peut pas être annulée',
  cart: 'Chariot',
  cartOnlyHasLaborInIt: '% le chariot ne comprend que la main d’œuvre',
  cartsOnlyHaveLaborInThem: '% les chariot ne comprennent que la main d’œuvre',
  cartTotal: 'Total chariot',
  cat: 'Cat.',
  catalogDiagram: 'Diagramme du catalogue',
  categories: 'Catégories',
  category: 'Catégorie',
  categoryDeletionError: 'Erreur de suppression de la catégorie',
  categoryNameAlreadyExists: 'Le nom de la catégorie existe déjà',
  categoryNameExceedsChars: 'Le nom de la catégorie dépasse 30 caractères',
  change: 'changement',
  changedStoreDueToAvailability:
    'Magasin changé en raison de la disponibilité de l’article',
  changeGraphic: 'Changer de graphique',
  changeLoaction: 'Changer d’emplacement',
  changeLocation: 'Changer d’emplacement',
  changeVehicle: 'Changer de véhicule',
  chemicals: 'Substances chimiques',
  choosePartType: 'Choisissez le type de pièce',
  choosePartTypeDescription: `Optimiser ma recherche d'échange en affichant d'abord tous les types de pièces qui correspondent au numéro de pièce spécifié avant d'afficher mes résultats`,
  chooseVehicleSystem: 'Choisir système de véhicule',
  clear: 'claire',
  clearAll: 'Effacer tout',
  clearAllFilters: 'Effacer tous les filtres',
  clearFilters: 'Effacer les filtres',
  clearSearch: 'Effacer la recherche',
  clearSelections: 'Effacer les sélections',
  click: 'Cliquer',
  clickButtonBelowToBeginAddingItemsToThisGroup:
    'Cliquez sur le bouton ci-dessous pour commencer à ajouter des éléments à ce groupe',
  clickingThisPartWillChangeGraphic:
    'Cliquer sur cette partie changera le graphique affiché à présent',
  clickPartToSelectAndHighlight:
    'Cliquer sur cette partie pour la sélectionner et la souligner dans la liste ci-dessous',
  clickToViewAvailableMfgList: 'Cliquer pour voir la liste MFG disponible',
  clickToViewPrintPartNumbers:
    "Cliquer pour voir / imprimer les numéros d'article",
  close: 'Fermer',
  closeGroups: 'Fermer les groupes',
  code: 'Code',
  commentOptional: 'Commentaire (option)',
  comments: 'Commentaires',
  continueRquest: 'Continuer la demande',
  copiedToClipboard: 'Copied to Clipboard', //TODO
  copypart: 'Copy Part', //TODO
  copyToAllCarts: 'copier sur tous les paniers',
  confirmation: 'Confirmation #',
  confirmationId: 'Confirmation d’identifiant',
  confirmNewPassword: 'Confirmer le nouveau mot de passe',
  congratulationsThisOrderHasQualifiedFor:
    'Félicitations ! Cette commande s’est qualifiée pour ',
  congratulationsThisOrderQualifiesFor:
    'Congratulations! This order qualifies for', // TO DO
  contactAdminToRequestPasswordReset:
    'Contacter l’administrateur pour demander la réinitialisation du mot de passe',
  contactUs: 'Nous contacter',
  core: 'Fixe',
  coreCost: 'Coût fixe',
  coreFee: 'Frais fixe',
  coreList: 'Liste fixe',
  coreOnly: 'Noyau uniquement',
  corePrice: 'Prix fixe',
  cost: 'Coût',
  condensed: 'Condensé',
  costChangedFrom: 'Cost changed from % to %', //TODO
  costBreakdown: 'Ventilation des coûts',
  costDetails: 'Détails du coût',
  costHighLow: 'Coût (élevé-faible)',
  costLowHigh: 'Coût (faible-élevé)',
  costSummary: 'Résumé du coût',
  createCategory: 'Créer la catégorie',
  createdDate: 'Date de création',
  createdOn: 'Créé le',
  crossReference: 'Cross Reference', //TODO
  crossReferenceResults: 'Cross Reference Results', //TODO
  createGroup: 'Créer groupe',
  createList: 'Créer liste',
  crossReferenceTool: 'cross-reference tool', // TODO
  crossReferenceToolNoResults: 'Cross Reference Tool',
  crossReferenceTooltipMsg:
    'Use our Cross Reference Tool for a complete list of all OE and aftermarket parts interchangeable with %', // TODO
  current: 'Courant',
  customize: 'Personnaliser',
  customMakes: 'Fabrications personnalisées',
  customizeMakeList: 'Personnaliser la liste des marques',
  selectTheMakesThatYouWantToSeeWhenAddingAVehicle: `Sélectionnez les marques que vous souhaitez voir lors de l'ajout d'un véhicule`,
  customerName: 'Nom du client',
  customizeSearch: 'Personnaliser la Recherche',
  customizeSearchPreferences: 'Personnaliser les préférences de recherche',
  customName: 'Nom personnalisé',
  clearVehicle: 'Effacer le véhicule', //TO DO
  damagedPartInbox: 'Boîte de réception des pièces endommagées',
  dashboard: 'tableau de bord', //TODO
  dateRange: 'Intervalle de date',
  dateRequested: 'Date demandée',
  delete: 'Supprimer',
  default: 'défaut',
  defaultVehicleType: 'Default vehicle type', // TODO
  defaultSetting: 'Défaut',
  defective: 'Défectueuse',
  deleteCategory: 'Supprimer catégorie',
  deleteGroup: 'Supprimer groupe',
  deleteItemFromList: 'Supprimer articles des listes',
  deleteItems: 'Supprimer articles',
  deleteList: 'Supprimer liste',
  deleteMessage: 'Êtes-vous sûr de vouloir supprimer',
  deleteQuote: 'Supprimer devis',
  deleteQuotes: 'Supprimer devis',
  deleteQuotesOlderThan: 'Supprimer devis plus anciens de',
  deleteVehicle: 'Supprimer véhicule',
  delivered: 'Livré',
  deliveryStatus: 'État de livraison',
  deliveryStatusUnavailable: 'Delivery Status Unavailable', // TODO
  deliveryStatusUnavailableOrderPlacedByOtherMeans:
    'Delivery status unavailable. This order was placed via phone, walk-in, or through another application.', // TODO
  description: 'Description',
  descriptionAZ: 'Description (A-Z)', //TODO
  descriptionZA: 'Description (Z-A)', //TODO
  descriptions: 'Descriptions',
  details: 'Détails',
  desktop: 'Bureau',
  diagram: 'Diagramme',
  diagrams: 'Diagrammes',
  didYouMeanToSearchFor: 'Did you mean to search for',
  dismiss: 'Rejeter',
  dispatchNotificationNotFound: 'Dispatch Notification Not Found', // TODO
  dontAskPartTypeAgain: `Ne demandez plus "Type de pièce"`,
  dontAskManufacturerAgain: `Don't ask "From Manufacturer" again`,
  doingSoWillRemoveAllItems: 'Cette action va supprimer tous les articles.',
  dragAndDrop: 'ou glisser-déposer ici',
  dragAndRearrangeTheItemsInThisListToArrangeThemInYourPreferredDisplayOrder:
    'Drag and drop the items in this list to arrange them in your preferred display order', // TODO
  duplicateVehicle: 'Doubler le véhicule',
  // eachAndEveryPerfectStopProductPurchaseearnsanenrty:
  //   'Every PerfectStop branded product earns you an automatic entry and a chance to WIN!',
  eachAndEveryPerfectStopProductPurchaseearnsanenrty:
    'Obtenez une chance de gagner pour chaque transaction admissible de 150 $ ou plus. Les utilisateurs de MyPlace4Parts obtiennent désormais une chance supplémentaire pour chaque tranche d’achats additionnels de 150 $',
  edit: 'Modifier',
  editCustomName: 'Modifier le nom personnalisé',
  editFitmentConditions: 'Modifier les conditions de montage',
  editItem: 'Modifier l’article',
  editList: 'Modifier la liste',
  editParts: 'Modifier les pièces',
  editSearch: 'Modifier la recherche',
  editUnknownItem: 'Modifier l’article inconnu',
  editVehicle: 'Modifier le véhicule',
  email: 'Courriel',
  emailAddress: 'Adresse de courriel',
  emailRequired: 'Courriel requis',
  emptyCart: 'Supprimer le chariot',
  engine: 'Moteur',
  english: 'Anglais',
  enterAPlateNumber: 'Entrer le numéro de plaque',
  enterCode: 'Entrer le code',
  enterNewPassword: 'Entrer le nouveau mot de passe',
  enterPart: 'Entrer le no. de pièce pour changer ou rechercher',
  enterVehicle: 'Enter Vehicle', // TODO
  enterVin: 'Entrer le NIV',
  entryAddjust: 'entrée(s). Ajouter uniquement ',
  entryToDate: 'entrée(s) jusqu’à présent.',
  entryYouHaveReachedTheMaximumNumberOfEntriesYouHave:
    'entrée(s). Vous avez atteint le nombre maximum d’entrées. Vous avez ',
  errorAddingProductsToTheCart: 'Erreur en ajoutant les produits au chariot',
  errorDeletingVehicle: 'Erreur en suppriment le véhicule',
  errorLoadingBannerPromotion: 'Erreur en chargeant la bannière promotionnelle',
  errorLoadingEntries: 'Erreur en chargeant les entrées',
  errorMovingList: 'Erreur en déplaçant la liste',
  errorOccurredWhileUpdatingVehicleName:
    'Une erreur a eu lieu lors de la mise à jour du nom du véhicule.',
  errorProcessingOrders: 'Erreur en traitant les commandes',
  errorUpdatingProductQuantity:
    'Erreur en mettant à jour la quantité du produit',
  errorValidatingList:
    'Une erreur a eu lieu lors de la validation de la liste.',
  estimatedDelivery: 'Livraison estimée',
  exactMatch: 'Correspondance exacte',
  existingCartWarningModal:
    'Vous avez dans votre chariot un véhicule qui correspond à ces spécifications. Voulez-vous utiliser un nouveau chariot ou combiner les produits dans le chariot existant % ?',
  expandAll: 'Étendre tout',
  experianAutocheck: 'Vérification Experian Autocheck',
  explanation: 'Explication',
  exportList: 'Exporter la liste',
  extraDetails: 'Extra Details', // TODO
  favorites: 'Favoris',
  failedToLogin: 'échec de connexion',
  feelFreeToReachTextHD:
    ' If you feel you have reached this message in error or have any questions, please contact us.',
  failedToAcceptTermsAndConditions: 'Failed to accept Terms and conditions', //TODO
  fieldsForShopUse: 'Champs utilisés par le magasin',
  filter: 'Filtrer',
  filterBy: 'Filtrer par',
  filterByDateRange: 'Filtrer par intervalle de date',
  filterResults: 'Filtrer les résultats',
  filterSelectLines: 'Filtrer',
  filterSort: 'Filter & Sort', // TODO
  fitmentConditions: 'Conditions de montage',
  fitsYears: 'Adéquat aux années',
  forFullSweepstakesDetails: 'pour les détails complets du tirage au sort',
  forgotPassword: 'Mot de passe oublié%',
  formIcomplete: 'Formulaire incomplet',
  forShopUse: 'Uniquement pour l’utilisation du magasin',
  french: 'Français',
  general: 'Généralités ',
  getLaborData: 'Obtenir les données liées à la main d’œuvre',
  getThePerfectResults: `Obtenez des résultats parfaits`,
  gfx: 'GFX',
  gfxCatalog: 'Catalogue GFX',
  gfxSpecificAndAttributeConditions: 'Conditions spécifiques et attributs GFX ',
  gfxUnavailableForThisVehicle: 'GFX indisponible pour ce véhicule',
  go: 'Aller',
  goToAccessoriesSuppliesAndTools:
    'Aller à accessoires, fournitures et outils (AST)',
  goToCart: 'Aller au chariot',
  goToFirstPage: 'Aller à la première page',
  goToNextPage: 'Aller à la page suivante',
  goToPreviousPage: 'Retour à la page précédente',
  grandTotal: 'Total général',
  graphicId: 'Identifiant graphique',
  group: 'Groupe',
  groupName: 'Nom du groupe',
  groupNameAlreadyExists: 'Le nom du groupe existe déjà',
  groups: 'Groupes',
  hashTagParts: 'No. de pièces',
  here: 'ici',
  hidden: 'caché',
  hideMore: 'Cacher plus',
  home: 'Accueil',
  homePage: 'Page d’accueil',
  hours: 'Programme',
  howToReadThisGraphic: 'Comment lire ce graphique',
  howToViewSearchResults:
    'Comment souhaitez-vous afficher vos résultats de recherche ?',
  id: 'IDENTIFIANT',
  itemWasnotAddedToYourCart: 'Item was Not added to your Cart', //To DO
  ifYouDoNotReceiveEmailWithCode:
    'If you do not receive an email with the code please contact your parts supplier.', //TODO
  ifYouNeedThisGraphic:
    'si vous avez besoin de ce graphique et nous traiteront votre demande aussi vite que possible.',
  import: 'Importer',
  importantMessageAboutItemsInYourCart:
    'Message important concernant les articles dans votre chariot',
  importComplete: 'Importation effectuée',
  importFile: 'IMPORTER UN FICHIER',
  importList: 'Importer la liste',
  importListDescription:
    'Importer plusieurs articles dans une liste à partir d’un fichier CSV. Elle devrait inclure des colonnes pour Groupe, MFR, Numéro de pièce, Qté de Stockage et Commentaires.',
  importListTemplateCSV: 'Utilisez notre liste de modèles (CSV)',
  inCart: 'Dans le chariot',
  increasedFromTo: '% est passé de % à %',
  interchange: 'Échanger',
  interchangesearch: `Recherche d'échange`,
  interchangeSearchTextError:
    'Interchange text length must be between 4 and 14 characters',
  interchangeDisclaimer:
    'Interchange results are for reference only. Application should be verified.', // TODO
  interchangeInfoMessage:
    'If you don’t see the item you are looking for, try using our % to see a complete list of all OE and aftermarket parts interchangeable with %', // TODO
  interchangeFor: 'Échanger pour',
  invalidEmailAddress: 'Adresse de courriel invalide',
  invalidVin: 'NIV invalide',
  invoiceDetails: 'InvoiceDetails', //TO DO
  invoicedOn: 'Invoiced On',
  inputPartTypeThenHitEnter: `Saisissez le type de pièce, puis appuyez sur la touche « Entrée » / « Retour » pour rechercher`, //TO DO
  isNotAvailableInDatabase: 'n’est pas disponible dans notre base de données.',
  issueInAddingPartsToCart:
    'There was an issue in adding your items to the cart. Please check the cart page for details', //TODO
  item: 'Article',
  itemInYourCartCantBeFulfilled:
    '1 article dans votre chariot ne peut pas être fourni',
  itemInYourCartHasChangedPrice:
    '1 article dans votre chariot a changé de prix',
  itemInYourCartHaveInsufficientQuantity:
    '1 article dans votre chariot a changé de quantité',
  items: 'Articles',
  itemsAddedFromMultipleLocations:
    'Item added from multiple locations. Select More Locations to update quantity',
  itemsAddedToTheCart: 'articles ajoutés au chariot',
  itemsAvailability: '% sur 8 disponible(s)',
  itemsInYourCartCantBeFulfilled:
    '% articles dans votre chariot ne peuvent pas être fourni',
  itemsInYourCartHaveChangedPrice:
    '% articles dans votre chariot ont changé de prix',
  itemsInYourCartHaveInsufficientQuantity:
    '% articles dans votre chariot ont changé de quantité',
  itemsPerPage: 'articles par page',
  itemsReflectTheCurrentPrices: 'Les articles reflètent les prix actuels',
  itemsRequireYourAttention: 'articles demandent votre attention',
  itemsRestock: 'Articles réapprovisionnés tous les quatre mois',
  itemsSelected: 'articles sélectionnés dans 1 liste',
  itemsWereNotTransferred: '% articles n’ont pas été transférés',
  itemUnavailable: 'Article indisponible dans notre base de données.',
  itemUnavailableAtAllLocations:
    'Article indisponible dans tous les emplacements.',
  itemvalidated: 'Article validé',
  itemWasNotAdded: 'articles non ajoutés au chariot',
  itemWasNotTransferred: '1 article n’a pas été transféré',
  jobs: 'Travaux',
  justAMoment: 'Veuillez patienter un moment.',
  labor: 'Main d’œuvre',
  laborCost: 'Coûts liés à la main d’œuvre',
  laborData: 'Données liées à la main d’œuvre',
  laborId: 'Identifiant de main d’œuvre',
  laborLookup: 'Rechercher main d’œuvre',
  laborOperationsDescriptions: 'Description des opérations de main d’œuvre',
  laborRate: 'Tarif de main d’œuvre',
  laborRatePerHour: 'Tarif de main d’œuvre par heure',
  laborsUpdated: 'Main d’œuvre mise à jour',
  last7Days: 'Derniers 7 jours',
  last12Months: 'Derniers 12 mois',
  last30Days: 'Derniers 30 jours',
  lastUsed: 'Dernière utilisation',
  lastUsedAZ: 'Last Used (Ascending)', //TODO
  lastUsedZA: 'Last Used (Descending)', //TODO
  license: 'Immatriculation',
  licensePlate: "Plaque d'immatriculation",
  lineCode: 'Code de ligne',
  Lines: 'Lignes',
  list: 'Liste',
  listDeletionError: 'Erreur de suppression de la liste',
  listHighLow: 'Liste (haut-bas)',
  listImportedError:
    '% lignes contenaient des erreurs et n’ont pas pu être importées',
  listImported: 'Liste importée avec succès',
  listImportedSuccessfully: 'Votre liste a été importée avec succès (% lignes)',
  listLowHigh: 'Liste (bas-haut)',
  listName: 'Nom de la liste',
  listNameAlreadyExists: 'Le nom de la liste existe déjà',
  listPrice: 'Prix de liste',
  listPriceBreakdown: 'Ventilation du prix de liste',
  lists: 'Listes',
  loadingXResults: 'Loading % results', // TODO
  location: 'Emplacement',
  logOut: 'Se déconnecter',
  locationChangedTo: 'Emplacement changé à',
  lookupDate: 'Date de recherche',
  lookupItems: 'Articles de recherche',
  lookupSource: 'Source de recherche',
  lookupType: 'Type de recherche',
  lostSaleSuccess: 'Rapport de vente perdue envoyé',
  make: 'marque',
  makeThisMyDefaultState: 'Faire ceci l’état par défaut',
  manufacturer: 'Fabricant',
  manufacturers: 'Fabricants',
  manufacturerInfo: `If you know the manufacturer of the part you are interchanging from, select it below. If not, click "Select All".`, // TODO
  markAsFavorite: 'Marquer comme préféré',
  markedAsFavorite: 'Marqué comme préféré',
  mayNotFitYour: 'May not fit selected vehicle', // TODO
  mergeWithExistingCart: 'Combiner avec le chariot existant',
  message: 'Message',
  messageSent: 'Message envoyé !',
  messageSentSuccess:
    'Merci pour votre message ; on vous contactera selon la priorité.',
  method: 'Method', // TODO
  mfr: 'Fbr',
  mfrAZ: 'Mfr (A-Z)', // TODO
  mfrZA: 'Mfr (Z-A)', // TODO
  mileage: 'Kilométrage',
  minQuantity: 'Quantité minimale',
  miscellaneous: 'Divers',
  missingPartInbox: 'Boîte de réception des pièces manquantes',
  model: 'Modèle',
  more: 'plus',
  moreOptions: 'Plus d’options',
  moreActions: 'More Actions', // TODO
  moreLocations: `Plus d'emplacements`,
  move: 'Déplacer',
  moveGroup: 'Déplacer le groupe',
  moveList: 'Déplacer la liste',
  moveToAnotherList: 'Déplacer dans une autre liste',
  mustIncludeAPartToOrderThisCart:
    'doit inclure une pièce pour commander ce chariot',
  multiPartInquiry: 'requête en plusieurs parties',
  multipleOpenCartText: `Vous avez plusieurs paniers ouverts dans votre panier. Sélectionnez le panier auquel vous souhaitez ajouter les articles.`,
  multiPartInquiryValidationError:
    'Code de ligne non valide, les caractères spéciaux ne sont pas autorisés/la longueur du code de ligne doit être de 2caractères minimum et de 3caractères maximum',
  myStore: 'Mon magasin',
  myPlaceForParts: 'MyPlace4Parts',
  name: 'Nom',
  needHelpResettingYourPassword:
    'Vous avez besoin d’aide pour réinitialiser votre mot de passe ?',
  new: 'Nouveau',
  newCategory: 'Nouvelle catégorie',
  newGroup: 'Nouveau groupe',
  newList: 'Nouvelle liste',
  newLists: 'Nouvelles listes',
  newParts: 'Nouvelles pièces',
  newPassword: 'Nouveau mot de passe',
  newReturn: 'Nouveau retour',
  next: 'Suivant',
  nextChooseList: 'Suivant',
  nextSelectGroup: 'SUIVANT',
  nextSelectDescription: 'SUIVANT: sélectionner une description',
  nextSelectPartTypes: 'SUIVANT',
  noAdditionalDetails: 'Aucun détail supplémentaire',
  noData: 'Aucune donnée disponible',
  noImage: 'Aucune image disponible',
  noMoreResults: 'Plus de résultats',
  noOrdersAvailable: 'Aucune ordres disponible',
  noInvoicesAvailable: 'Aucune ordres disponible', //TODO
  noPartsFoundForThisGroup: 'Aucune pièce trouvée dans ce groupe',
  noPartsFoundForThisList: 'No parts found in this list', // TODO
  noPartsFoundAddedToCart: 'No parts were added to the cart', // TODO
  noQuotesAvailable: 'Aucun devis disponible',
  noResults: 'No Results', //To DO
  noResultsFound: 'Aucun résultat trouvé',
  noResultsFoundForHD: `NO RESULTS FOUND FOR "%"`,
  noListResultsFound: 'No Lists Found', //TO DO
  noResultsFoundForThisVehicle: 'Aucun résultat trouvé pour ce véhicule',
  normal: 'Normal',
  note: 'Remarque',
  notes: 'Remarques',
  noteToStore: 'Remarque à stocker',
  notesToStore: 'Remarques à stocker',
  noVehicleHistoryAvailable: 'Aucun historique de véhicule disponible',
  oePart: 'Pièce OE',
  oeParts: 'oeParts', //TODO
  oePartNumbersDisclaimer:
    'Les numéros de pièce OE pour référence uniquement. L’application devrait être vérifiée.',
  okIUnderstand: 'Bien, je comprends',
  onThisOrderFor: 'sur cette commande pour',
  onThisOrderForAnAutomaticEntry:
    'sur cette commande pour une entrée automatique.',
  onlineCatalog: 'Catalogue en ligne',

  onlyLaborResultsAreAddedToCart: 'Only Labor Results are added to cart', //TODO
  onlyOutOfRequestedAreAvailable:
    'Disponibilité uniquement % sur % de la demande',
  'Only Priced': 'Seulement au Prix',
  onlySpecsAreAddedToCart: 'Only specifications are added to cart', //TODO

  onlySpecsLaborResultsAreAddedToCart:
    'Only Specifications and Labor Results are added to cart', //TODO
  'Only Stocked': 'Seulement en Stock',
  openGroups: 'Ouvrir groupes',

  or: 'OU',
  order: 'Commander',
  orderAllCarts: 'Commander tous les chariots',
  orderConfirmation: 'Confirmation de commande',
  orderConfirmationError:
    'Please review the error message above and resubmit your order from the Cart page.if you continue to have issues, please contact us.',
  orderAllCartsConfirmation: 'Are you sure you wish to Order All Carts?', // TODO
  orderDate: 'Order Date', // TODO
  orderDetails: 'Détails de la commande',
  ordered: 'Commandé',
  orderedBy: 'Commandé par',
  orderedOn: 'Commandé le %',
  orderFailed: `Commande échouée`,
  orderForm: 'Formulaire de commande',
  orderFormIsIncomplete: 'Formulaire de commande incomplet.',
  orderHistory: 'Historique de commande',
  orderNotFound: 'Commande non trouvée',
  orderNumber: 'Numéro de commande',
  orders: 'Commandes',
  orderError: 'Error: Order Failed. ',
  orderSummary: 'Sommaire de la commande',
  orderThisCart: 'COMMANDER CE CHARIOT',
  orderTotal: 'Total commande',
  orderFromPrimaryWarining:
    'Les commandes à cet endroit seront traitées par votre magasin local',
  OrdersWillBeProcessedByYourLocalStore:
    'Les commandes seront traitées par votre magasin local',
  orderType: 'Type de commande',
  orderConfirmationNumber: 'Confirmation de commande Id', //TODO
  other: 'Autre (veuillez spécifier)',
  others: 'Other',
  outOf: 'rupture',
  outOfStock: 'Rupture de stock',
  outOfStockAtAllLocationsWillBeOrderedFromOtherStore:
    'Rupture de stock dans tous les emplacements, commander à partir du %',
  outOfStockAtUpdatedTo: 'rupture de stock au %, mise à jour le %',
  packed: 'Emballé',
  page: 'Page',
  part: 'Pièce',
  'Part#': 'Pièce #',
  partIsValidSubText: `You may use our cross reference tool to see a complete list of all OE and aftermarket parts interchangeable with % or try another search`,
  partIsValidText: `% is a valid part number, but we do not carry this part or any interchangeable products.`,
  partVehicleFitment: 'Pièce', //To DO
  partAvailableInAnotherGroup: 'Pièce disponible dans un autre groupe',
  partAvailableInCurrentScreen: 'Pièce disponible dans l’écran courant',
  partDescription: 'Description de la pièce',
  partDescriptionAZ: 'Description de la pièce (A-Z)',
  partDescriptionZA: 'Description de la pièce (Z-A)',
  partNum: 'No. de pièce',
  partNumber: "Numéro d'article",
  partNumberAZ: "Numéro d'article (A-Z)",
  partNumberZA: "Numéro d'article (Z-A)",
  partsCatalog: 'Catalogue de pièces',
  partSelected: 'Pièce sélectionnée',
  partType: 'Type de pièce',
  PartType: 'Type de pièce',
  partTypes: 'Types de pièces',
  partInquiry: 'Demande de pièces',
  partWasAddedToYourCart: '% ajouté au chariot',
  partWasRemovedFromYourCart: '% enlevé du chariot',
  partsHaveBeenAddedToYourCart: 'Les pièces ont été ajoutées à votre panier',
  password: 'Mot de passe',
  passwordReset: 'Réinitialiser le mot de passe',
  passwordsMustMatch: 'Les mots de passe doivent correspondre',
  pending: 'en attente',
  perCarQty: 'Qté par chariot',
  personalNote: 'Remarque personnelle',
  phoneNumber: 'Numéro de téléphone',
  phoneNumberRequired: 'Numéro de téléphone requis',
  pleaseContactForInformation: 'Veuillez contacter % pour plus d’informations',
  pleaseEnterTheCodeThatWasSentTo:
    'Veuillez entrer le code qui vous a été envoyé à %',
  pleaseEnterYourContactDetailsAndAnAdminWillBeInContactWithYou:
    'Veuillez entrer votre pseudonyme, adresse de courriel et numéro de téléphone ; un administrateur va vous contacter',
  pleaseEnterYourScreenNameToSendVerificationCode:
    'Veuillez entrer votre pseudonyme pour envoyer un code de vérification à votre adresse de courriel',
  pleaseUploadValidCsvFile: 'veuillez télécharger un fichier CSV valide',
  pleaseUploadCsvFile: 'veuillez télécharger le fichier csv',
  po: 'Bon de commande',
  poNumber: 'Numéro du bon de commande',
  previousSearches: 'Recherches précédentes',
  price: 'Prix',
  pricePerItem: 'Prix par article',
  primary: 'Primaire',
  print: 'Imprimer',
  printList: ' Imprimer liste',
  printCatalogDiagram: 'Print Catalog Diagram', //TODO
  priority: 'Priorité',
  ProcessedByLocalStore: 'Traité par le magasin local',
  productCantBeFulfilled:
    'Le produit ne peut pas être fourni de tous les emplacements',
  productCantBeFulfilledFromSelectedLocation:
    "Product Can't be fulfilled from the selected location",
  productToThisOrderForAnAutomaticEntry:
    'product to this order for an automatic entry.', // TO DO
  products: 'Produits',
  productsWereAddedToTheCart: 'Les produits ont été ajoutés au chariot',
  promoPolicy:
    'pour les détails complets du tirage au sort. *Estimation. Entrées réelles MyPlace4Parts mises à jour dans les 24-48 heures',
  purolatorCutoffTimeMessage: 'If ordered by % - Monday through Friday', // TODO
  purolatorDisclaimerMessage1:
    'Veuillez noter que les frais de transport sont approximatifs et peuvent varier en fonction du poids réel et de la dimension des articles.', // TODO
  purolatorDisclaimerMessage2:
    'Un supplément de transport peut être applicable sur tous les articles classés comme MARCHANDISES DANGEREUSES.',
  purolatorDisclaimerMessage3:
    'Les commandes passées un samedi ou un dimanche seront traitées comme des commandes du lundi.',
  purolatorShippingEstimate: "Estimation d'expédition Purolator",
  qty: 'Qté',
  qtyAZ: 'Qty (Low-High)', //TODO
  qtyZA: 'Qty (High-Low)', //TODO
  qtyPriceBreak: 'Prix proposé par qté',
  qtyToReturn: 'QTÉ À RETOURNER',
  quantity: 'Quantité',
  quantityPriceBreak: 'Prix proposé par quantité',
  quantitySelectedIsUnavailableAt:
    'La quantité sélectionnée n’est pas disponible à',
  quantityRequestedIsLessThanMinimum: 'Quantity requested is less than minimum', //TODO
  quantityRequestedHasChanged: 'Quantity requested has changed.', //TODO
  quoteDeletedSuccessfully: 'Devis supprimé avec succès',
  quoteDetails: 'Détails du devis',
  quoteId: 'Identifiant du devis',
  quoteNotFound: 'Devis non trouvé',
  quotes: 'Devis',
  quotesOlderThan: 'devis plus anciens de',
  quoteSummary: 'Résumé du devis',
  quoteVehicleNotFound: 'Données du véhicule non trouvées sur le devis',
  rate: 'Taux',
  readLess: 'Lire moins',
  readMore: 'Lire plus',
  reason: 'Raison',
  reasonForReturn: 'RAISON DU RETOUR',
  recentOrders: 'Commandes récentes',
  recentSearches: 'Recherches récentes',
  recentVehicles: 'Véhicules récents',
  recommended: 'Recommandé',
  recommendedServices: 'Services recommandés',
  removeAll: 'Supprimer tout',
  removeAllCarts: 'Supprimer tous les chariots',
  removeCart: 'Supprimer le chariot',
  removeItem: `Retirer l'objet`,
  removeItemFromReturnRequest: `Supprimer l'article de la demande de retour`,
  removeYour: 'supprimer votre',
  rename: 'Renommer',
  reName: 'Renommer',
  renameCategory: 'Renommer la catégorie',
  reNameGroup: 'Renommer le groupe',
  renameList: 'Renommer la liste',
  replacementFor: 'Remplacement pour',
  reportLostSale: 'Rapporter une vente perdue',
  requestNewCode: 'Demander un nouveau code',
  requestPasswordReset: 'Demander de réinitialiser le mot de passe',
  resetYourPassword: 'Réinitialisez votre mot de passe',
  resortItems: 'Resort Items', // TODO
  results: 'Résultats',
  resultsFilter: 'Résultats Filtrer',
  resultsLoaded: 'Results loaded', // TODO
  returnAmountText:
    'Ce montant sera recrédité sur votre compte une fois la demande de retour traitée et autorisée.',
  returnRequestDetails: 'Détails de la demande de retour',
  requestReturnId: 'Demander un numéro de retour',
  resultsTableDensity: 'Densité du tableau des résultats',
  returnToThePartsCatalogToKeepShopping:
    'Revenir au catalogue de pièces pour continuer les achats.',
  returnToVehicleHistory: 'Return to vehicle history', // TODO
  reviewItemsaddedtocart: 'Analyser les articles ajoutés au chariot',
  rma: 'RMA',
  rmaRequest: 'Demande RMA',
  rmaConfirmation: 'Confirmation RMA',
  rollOverImageToMagnify: 'Roll over image to magnify', // TODO
  saveAll: 'Sauvegarder tout',
  saveAsQuote: 'Sauvegarder comme devis',
  saveChanges: 'Sauvegarder les changements',
  savePreferences: 'Sauvegarder les préférences',
  screenName: 'Pseudonyme',
  screenNameRequired: 'Pseudonyme requis',
  search: 'Rechercher',
  searchSettings: 'Paramètres de recherche',
  searchAllOrders:
    'Rechercher des commandes (par BC, numéro de pièce, véhicule)',
  searchAllInvoices: 'Search all invoices (by PO,part #,invoice number)', //TO DO
  searchAllQuotesBy:
    'Rechercher tous les devis (par bon de commande, numéro de pièce, notes, emplacement)',
  searchByLicensePlate: "Rechercher par plaque d'immatriculation",
  searchByVin: 'Rechercher par NIV',
  searchByYMME: 'Rechercher par année, marque, modèle',
  searchDate: 'Rechercher la date',
  searchForVehicles:
    "Recherche de véhicules (nom personnalisé, plaque d'immatriculation...)",
  searchManufacturers: 'Rechercher les fabricants',
  searchOnWeb: 'Search on Web', //TODO
  searchPartBrandName:
    'Rechercher le numéro de pièce, la marque ou le nom de la pièce',
  searchParts: 'Rechercher des pièces',
  searchWithinResults: 'Search within results', //TODO
  searchbyPartTypes: 'Recherche par types de pièces',
  secondary: 'Secondaire',
  seeLess: 'Voir moins',
  seeMore: 'Voir plus',
  select: 'Sélectionner',
  selectAConditionToChangeGraphic:
    'Sélectionner une condition pour changer le graphique affiché',
  selectAll: 'Tout sélectionner',
  selectAllItemsInThisList: 'Sélectionnez tous les éléments de cette liste',
  selectAVehicle: 'Sélectionner un véhicule',
  selectAnotherLocation: 'Select Another Location', //TO DO
  selectCart: `Sélectionnez un panier`,
  selectCartToAddItem: 'Sélectionner le chariot pour ajouter l’article',
  selectCategory: 'Sélectionner la catégorie',
  selected: 'sélectionné',
  selectedAcross: 'sélectionné à travers',
  selectFromManufacturer: `Sélectionnez "Du" fabricant`,
  selectFromManufacturerDescription: `Optimiser ma recherche d'échange en affichant d'abord tous les fabricants "de" qui correspondent au numéro de pièce spécifié avant d'afficher mes résultats`,
  selectedLines: 'Ligne sélectionnées',
  selectGfxAndFitmentConditions: 'Sélectionner GFX et conditions de montage',
  selectGroup: 'Sélectionner groupe',
  selectManufactureLines:
    'Sélectionner les lignes de fabricant pour lesquelles vous aimeriez voir les résultats.',
  selectManufacturersUpto: 'Vous pouvez sélectionner jusqu’à 10 fabricants.',
  selectPartType: 'Sélectionner le type de pièce',
  selectPartTypes: 'selectPartTypes', // TODO
  selectVehicle: 'Sélectionner véhicule',
  selectVehicleEngine: 'Sélectionner le moteur du véhicule',
  selectYourPreferredDefaultVehicleTypeWhenDoingAYMMESearch:
    'Select your preferred default vehicle type when doing a YMME search',
  sendAMessageTo: 'Envoyer un message à',
  sendAnother: 'Envoyer un autre',
  sendCode: 'Envoyer un code',
  sendMessage: 'Envoyer le message',
  sendUsaMessage: 'Envoyez-nous un message',
  separateMultiplePartNumbers:
    'Séparer plusieurs no. de pièce avec des espaces',
  separateMultiplePartNumbersWithSpaces:
    'séparer plusieurs numéros de pièce avec des espaces',
  serviceIntervals: 'Intervalles de service',
  servicesIntervalType: 'Type des intervalles de service',
  setLaborRate: 'Établir le tarif de main d’œuvre',
  severe: 'Grave',

  shipByCourier: 'Expédier par courrier',
  shipTo: 'Livrer à',
  shipment: 'Livraison',
  shipmentCost: "Coût d'expédition",
  shipped: 'Livré',
  shippedFrom: 'Livré de',
  shipping: 'Expédition',
  shippingCostIsUpdated:
    "Vos frais d'expédition ont changé en fonction de la quantité mise à jour",
  shippingId: 'Identifiant de livraison',
  // i.e: "Commandes 1-10 sur 391 affichées",
  'Show All': 'Afficher Tout',
  showGraphic: 'Afficher le graphique',
  showingResultsFor: 'Afficher les résultats pour',
  showing: 'montrant',
  showResults: 'Afficher les résultats',
  showLess: 'Show Less', // TODO
  showMore: 'Show More', // TODO
  skillLevel: 'Niveau de compétences',
  skipAndSeeAllResults: 'Passer et voir tous les résultats',
  sort: 'Trier',
  sortBy: 'Trier par',
  sortGroup: 'Sort Group', // TODO
  sortHasNotYetBeenApplied: 'Sort has not yet been applied', // TODO
  sortResult: 'Sort Results', //TODO
  spanish: 'Espagnol',
  source: 'Source', // TODO
  specifications: 'Spécifications',
  specificationSearch: 'Spécifications de recherche',
  specificationUpdate: 'Spécifications mises à jour',
  specify: 'Si autre, veuillez spécifier',
  startNewCart: 'Commencer nouveau chariot',
  state: 'État',
  status: 'État',
  statusUnavailable: 'Status Unavailable', // TODO
  stock: 'Action',
  subject: 'Sujet',
  submit: 'soumettre',
  subOperation: 'Sous-opération',
  subModel: 'sous-modèle',
  successfullyCreatedCategory: 'Catégorie créée avec succès',
  successfullyCreatedGroup: 'Groupe créé avec succès',
  successfullyCreatedList: 'Liste créée avec succès',
  successfullyDeletedCategory: 'Catégorie supprimée avec succès',
  successfullyDeletedGroup: 'Groupe supprimé avec succès',
  successfullyDeletedList: 'Liste supprimée avec succès',
  successfullyListMoved: 'Liste déplacée avec succès',
  successfullyUpdatedCategory: 'Catégorie mise à jour avec succès',
  successfullyUpdatedGroup: 'Groupe mis à jour avec succès',
  successfullyUpdatedList: 'Liste mise à jour avec succès',
  summary: 'Sommaire',
  switchOrg: 'Switch Org', //TODO
  theFollowingItemsWereLoaded:
    'The following items were loaded after you applied a custom sort. To apply your sort to the items below click resort items', // TODO
  theMinimumOrderQuantityIs: 'La quantité minimum de commande est %.',
  thenBy: 'Puis par',
  thePartWasAddedToYourCart: 'La pièce a été ajoutée au chariot',
  fewOfThePartsWereNotAddedToCart:
    'few of the parts were not added to the cart', //TODO
  NoPartWasNotAddedToTheCart: 'No part Was added to your Cart', //TODO
  theQuantityRequestedIsNotAvailable:
    "La quantité demandée n'est pas disponible",
  theseItemsWereUnavailableFromOurDatabase:
    'Ces articles n’étaient pas disponibles dans notre base de données',
  thesePartsMayNotFitYour:
    'Ces pièces peuvent ne pas être compatibles avec votre',
  theseTicketsWillNotBeSentOnTheTicketToTheWarehouse:
    'Ces champs ne seront pas envoyés sur la note au dépôt.',
  thisCannotBeUndone: 'Cette action ne peut pas être annulée.',
  thisGroupIsEmpty: 'CE GROUPE EST VIDE',
  thisIncludesTheFollowingCarts: 'Cela comprend les chariots ci-dessous',
  thisItemWasUnavailableFromOurDatabase:
    'Cet article n’était pas disponible dans notre base de données',
  thisOrderHasQualifiedFor: 'Cette commande s’est qualifiée pour',
  thisQuoteCantBeFulfilled: 'This Quote can not be fulfilled', //TODO
  thisVinIsInvalidTryAgain:
    'Ce NIV est invalide. Veuillez vérifier le NIV et réessayer.',
  invalidQuery: 'Invalid Query, search term should be atleast 3 characters.', //TODO
  thisWeek: 'Cette semaine',
  thisWillRemoveAllItemsFromTheCartAndCannotBeUndone:
    'Cette action va enlever tous les articles du chariot, sans pouvoir être annulée.',

  today: 'Aujourd’hui',
  toEnsureWeCanFindTheBestMatchPleaseEnterVehicleInformation:
    'Pour nous assurer que nous pouvons trouver la meilleure correspondance, veuillez saisir les informations sur le véhicule',
  tomorrow: 'Demain',
  total: 'Total',
  totalApplications: 'total Applications',
  toHideAnItemClickTheEyeIcon: 'To hide an item, click the eye icon', // TODO
  totalCost: 'Total coût',
  totalHours: 'Total heures',
  totalList: 'Total liste',
  totalQty: 'Total QTÉ',
  trackingHistory: 'Historique de suivi',
  trackingInformationIsNotYetAvailable:
    'Tracking information is not yet available for this shipment, please check back later', //TODO
  trackingShipment: 'Suivi d’expédition',
  transfer: 'Transfert',
  transport: 'Transport',
  tryAdjustingYourFilters: 'Essayer de régler les filtres',
  tryAdjustingYourSearchOrLookupPartsInOurDatabase:
    'Essayer de régler la recherche ou rechercher des pièces dans notre base de données',
  tryANewSearchOrEditYourFitmentConditions:
    'Essayer une nouvelle recherche ou modifier les conditions de montage',
  tryANewSearchOrUseThePartsCatalog:
    'Essayer une nouvelle recherche ou utiliser le catalogue de pièces',
  trySelectingDifferentGroups:
    'Essayer de sélectionner des groupes différents ou de rechercher un autre véhicule',
  type: 'Taper',
  typeANameForThisVehicle: 'Taper un nom pour ce véhicule',
  unableToFetchManufacturer: 'Unable to fetch manufacturer',
  unableToFetchPartTypes: 'Unable to fetch part types',
  unableToPlaceOrder: 'Impossible de passer la commande',
  unableToPlaceOrderError: `Impossible de passer la commande: Demande de pièce/Erreur de commande`,
  unableToPlaceOrderPleaseContactTheAdministrator:
    'Impossible de passer la commande. Veuillez contacter l’administrateur.',
  unavailableGroupGraphic: 'Graphique de groupe indisponible',
  unavailablePartTypes: 'Types de pièces indisponibles',
  unavailableProductDetails: 'Détails du produit indisponibles',
  underWarranty: 'Sous garantie',
  unDone: 'Cette action ne peut pas être annulée.',
  unknownAftermarketAvailability:
    'Disponibilité inconnue sur le marché secondaire',
  unknownError: "Une erreur inconnue s'est produite",
  unknownErrorPurolatorEstimates:
    "Une erreur inconnue s'est produite lors de la récupération des estimations de Purolator",
  unSelectAll: `désélectionner Tout`,
  update: 'Mise à jour',
  updateCart: 'Mise à jour du chariot',
  updateList: 'Mise à jour de la liste',
  updateQuote: 'Mise à jour du devis',
  updateVehicle: 'Mise à jour du véhicule',
  updatingYourVehicleWillChangeSearch:
    'La mise à jour du véhicule va changer les résultats actuels de recherche. Voulez-vous continuer la mise à jour ?',
  urgent: 'Urgent',
  user: 'Utilisateur',
  usedPartInbox: `Boîte de réception des pièces d'occasion`,
  useTemplateCsv: 'Utilisez notre liste de modèles (CSV)',
  vehicle: 'Véhicule',
  vehicleClear: 'claire',
  vehicleDeleted: 'Véhicule supprimé',
  vehicleDeletedSuccessfully: 'Véhicule supprimé avec succès',
  vehicleFitment: 'Adaptation au véhicule',
  vehicleFitmentUnavailable:
    'L’adaptation au véhicule est indisponible pour ce produit.',
  vehicleHistory: 'Historique du véhicule',
  vehicleIncomplete: 'Véhicule incomplet',
  vehicleInformationWasIncomplete:
    'Données du véhicules incomplètes. Veuillez ajouter les autres données du véhicule',
  vehicleInfoRetrievalError:
    'An error occurred while trying to retrieve the vehicle information.', // TODO
  vehicleLicensePlate: "Plaque d'immatriculation",
  vehicleNotFound: 'Véhicule non trouvé',
  vehicleQuickFind: 'Recherche rapide de véhicule', // TODO
  vehicleRequired: 'Véhicule requis',
  vehicleRequiredForSearch: 'Veuillez sélectionner un véhicule manuellement.',
  vehicles: 'Véhicules',
  vehicleSearch: 'Rechercher véhicules',
  vehicleSpecification: 'Spécification',
  vehicleSuccessfullyDeleted: 'Véhicule supprimé avec succès',
  vehicleType: 'Type de véhicule',
  vehicleTypeDropdown: 'Vehicle type dropdown', // TODO
  vehicleVIN: 'NIV',
  vehicleYMME: 'Année / Marque / Modèle / Moteur',
  verifyingQuantityAvailable: 'Vérification de la quantité disponible...',
  verifyingTheItemsInOurDatabase:
    'Vérification des articles dans notre base de données...',
  verifyingTheLocationForItems: 'Vérification de l’emplacement des articles...',
  viewCart: 'Visualiser le chariot',
  viewDetails: 'Visualiser les détails',
  viewLists: 'Visualiser les listes',
  viewMore: 'Visualiser plus',
  viewOnly: 'Visualiser uniquement',
  viewOnlyLocationMessage:
    'View Only Location. Please select your local warehouse or store to order.', // TODO
  viewOrderDetails: 'Visualiser les détails de la commande',
  viewOrPrint: 'Visualiser ou imprimer le reçu',
  viewOrPrintQuote: 'Visualiser ou imprimer le devis',
  viewPartAvailabilityInListBelow:
    'Visualiser la disponibilité de la pièce dans la liste ci-dessous',
  viewPreviousSearches: 'Visualiser les recherches précédentes',
  viewQuoteDetails: 'Visualiser les détails du devis',
  viewVinDetails: 'Visualiser les détails du NIV',
  vin: 'VIN',
  vinScan: 'VIN analyse',
  vinCode: 'Code NIV',
  vinDetails: 'Détails du NIV',
  viewQuotes: 'Voir les devis',
  vinMustContain17Characters: 'Le NIV doit avoir 17 caractères',
  vinPattern: 'Modèle NIV',
  visitTheWebsiteForOrderDetails:
    'Visiter le site Web pour les détails de la commande',
  warrantyHours: 'Heures de garantie',
  warrantyInfo: 'Informations de garantie',
  wasAddedToList: 'a été ajouté à la liste',
  wasSavedAsQuote: '% a été enregistré comme devis',
  weAreValidatingAvailability:
    'Nous sommes en train de vérifier la disponibilité.',
  weUpdatedYourLocationTo: 'Votre emplacement a été mis à jour à',
  weight: 'Lester',
  wereSorryAGraphicFor: 'Désolé, un graphique pour',
  wereSorryTheProductDetailsFor:
    '% articles dans votre chariot ne peut pas être fourni',
  whyAreYouReturningThisPart: 'Pourquoi retournez-vous cette pièce?',
  wildCardSearchNotAllowedText: 'Wildcard search is not allowed',
  wrongPartFromCatalog: 'Wrong Part From Catalog',
  wtyHours: 'Heures de GARANTIE',
  year: 'Année',
  years: 'Années',
  yesEmpty: 'Oui, vider',
  yesIUnderstand: 'Oui, je comprends',
  yesterday: 'Hier',
  youHaveReachedTheMaximumNumberOfEntries:
    'Vous avez atteint le nombre maximum d’entrées.',
  youHaveEntryToDate: 'You have % entry(s) to date.', // TODO
  yourCartIsEmpty: 'Votre chariot est vide',
  yourCartWasSuccessfullyEmptied: 'Votre chariot a été vidé avec succès',
  yourCost: 'Votre coût',
  yourFiltersReturnedNoResults: 'Vos filtres n’ont généré aucun résultat',
  yourFirstItemWillBeYourDefault:
    'Votre premier article sera l’article par défaut',
  yourSearchReturnedNoResults: 'Votre recherche n’a généré aucun résultat',
  zeroAvailableAtLocation: '0 disponible à %',
  'Not Available': 'Pas disponible',
  'Price Too High': 'prix trop élevé',
  'Prefer Different Brand': 'préfère une autre marque',
  Other: 'other', //TO DO
  otherInfo: 'Autre',
  CAR: 'CAR', //TO DO
  TRUCK: 'TRUCK', //TO DO
  'MEDIUM DUTY': 'MEDIUM DUTY', //TO DO
}
