import { Warning } from '@mui/icons-material'
import { CSSProperties, ReactElement } from 'react'
import { I18n } from 'src/i18n'
import { defaultTheme } from 'src/theme'
import styled from 'styled-components'

interface NotPrimaryLocationLabelProps {
  name: string
  style?: CSSProperties
}

const NotPrimaryLocationLabel = ({
  name,
  style,
}: NotPrimaryLocationLabelProps): ReactElement => {
  return (
    <FlexContainer>
      <StyledWarning theme={defaultTheme} />
      <I18n
        name={name || 'notPrimaryLocation'}
        style={{
          fontSize: '14px',
          fontWeight: 'normal',
          marginLeft: '0.2rem',
          ...style,
        }}
      />
    </FlexContainer>
  )
}

const FlexContainer = styled.span`
  display: flex;
`

const StyledWarning = styled(Warning)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
`

export default NotPrimaryLocationLabel
